import React, { useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"
import classNames from "classnames"

import Button from "~/components/ui/button"
import Video from "~/images/home/video.mp4"
import Play from "~/images/home/play.svg"

import * as styles from "./index.module.scss"

import useAbout from "~/hooks/cms/home/useAbout"

const LinkResolver = (...props) => {
  return <p>hello</p>
}

const AboutSection: React.FC = () => {
  const data = useAbout()

  const [clicked, setClicked] = useState(false)

  const videoRef = useRef(null)

  const startVideo = () => {
    videoRef.current.play()
    setClicked(true)
  }

  return (
    <section className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <h2
            className={classNames("headline--decorated", styles.info__headline)}
          >
            <span>About</span> Urus
          </h2>
          <div className={styles.info__text}>
            <RichText render={data.primary.text.raw} />
          </div>
          <div className={styles.info__button}>
            <Button isLink={true} to="/who-we-are">
              Learn More
            </Button>
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.main__img}>
            <GatsbyImage
              alt={data.primary.frame_background.alt || ""}
              image={
                data.primary.frame_background.localFile.childImageSharp
                  .gatsbyImageData
              }
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </div>
          <div className={styles.main__frame__wrapper}>
            <div className={styles.main__frame}>
              <h2 className={styles.main__headline}>
                {data.primary.frame_title.text}
              </h2>
              {data.primary.video.localFile && (
                <button onClick={startVideo} className={styles.main__button}>
                  <Play />
                </button>
              )}
            </div>
          </div>
          {data.primary.video.localFile && (
            <video
              ref={videoRef}
              className={classNames(styles.main__video, {
                [styles.main__video__playing]: clicked,
              })}
              controls
            >
              <source
                src={data.primary.video.localFile.publicURL}
                type={`video/${data.primary.video.localFile.extension}`}
              />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </div>
    </section>
  )
}

export default AboutSection
