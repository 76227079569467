import React from "react"
import * as styles from "../index.module.scss"

const Info: React.FC = () => {
  return (
    <div className={styles.info}>
      <div className={styles.info__stripe} />
      <h1 className={styles.info__headline}>
        It All Centers <span>Around The Cow</span>
      </h1>
    </div>
  )
}

export default Info
