import React from "react"
import * as styles from "./index.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"

import Link from "~/components/link"
import JetstreamSVG from "~/images/home/companies/jetstream.svg"
import PeakSVG from "~/images/home/companies/peak.svg"
import PeakCopySVG from "~/images/home/companies/peak_copy.svg"
import VasSVG from "~/images/home/companies/vas.svg"
import ScclSVG from "~/images/home/companies/sccl.svg"
import AltaSVG from "~/images/home/companies/alta.svg"
import GenexSVG from "~/images/home/companies/genex.svg"
import TransOvaSVG from "~/images/home/companies/trans_ova.svg"
import AgSourceSVG from "~/images/home/companies/ag_source.svg"

import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"

const CompaniesSection: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      cow: file(relativePath: { eq: "home/cow2.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      alta: file(relativePath: { eq: "home/companies/alta.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      genex: file(relativePath: { eq: "home/companies/genex.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      jetstream: file(relativePath: { eq: "home/companies/jetstream.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      vas: file(relativePath: { eq: "home/companies/vas.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      sccl: file(relativePath: { eq: "home/companies/sccl.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      peak: file(relativePath: { eq: "home/companies/peak.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      transOva: file(relativePath: { eq: "home/companies/trans_ova.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      agSource: file(relativePath: { eq: "home/companies/ag_source.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  `)
  return (
    <section className={styles.root}>
      <div className={styles.backgroundImage}>
        <GatsbyImage
          alt="Cow"
          image={data.cow.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.desktop}>
          <div className={styles.main}>
            <div className={styles.main__row}>
              <div className={styles.main__block}>
                <div className={styles.main__info}>
                  <h2
                    className={classNames(
                      "headline--decorated",
                      styles.main__headline
                    )}
                  >
                    Urus <span>Companies</span>
                  </h2>
                  <div>
                    <p className={styles.main__subheadline}>
                      Urus is the source
                    </p>
                    <p className={styles.main__paragraph}>
                      for technologies to improve the profitability of
                      progressive commercial dairy and beef operations
                      worldwide.
                    </p>
                  </div>
                </div>
              </div>
              <div
                tabIndex={0}
                className={classNames(styles.main__block, styles.alta__block)}
              >
                <div className={styles.main__img}>
                  <GatsbyImage
                    alt="Man with a cow"
                    image={data.alta.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </div>
                <div className={styles.main__logo}>
                  <AltaSVG />
                </div>
                <Link
                  aria-label="Alta company page"
                  to="/companies/alta-genetics"
                  classNames={styles.main__link}
                >
                  Learn More
                </Link>
              </div>

              <div className={styles.main__block}></div>

              <div
                tabIndex={0}
                className={classNames(styles.main__block, styles.genex__block)}
              >
                <div className={styles.main__img}>
                  <GatsbyImage
                    alt="Family sitting at the back of the pickup truck"
                    image={data.genex.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </div>
                <div className={styles.main__logo}>
                  <GenexSVG />
                </div>
                <div>
                  <Link
                    aria-label="Genex company page"
                    to="/companies/genex"
                    classNames={styles.main__link}
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>

            <div className={styles.main__row}>
              <div
                tabIndex={0}
                className={classNames(styles.main__block, styles.sccl__block)}
              >
                <div className={styles.main__img}>
                  <GatsbyImage
                    alt="Employees at the production center"
                    image={data.sccl.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </div>
                <div className={styles.main__logo}>
                  <ScclSVG />
                </div>
                <Link
                  aria-label="SCCL company page"
                  to="/companies/sccl"
                  classNames={styles.main__link}
                >
                  Learn More
                </Link>
              </div>

              <div className={styles.main__block}></div>

              <div
                tabIndex={0}
                className={classNames(styles.main__block, styles.vas__block)}
              >
                <div className={styles.main__img}>
                  <GatsbyImage
                    alt="Workers in the field"
                    image={data.vas.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </div>
                <div className={styles.main__logo}>
                  <VasSVG />
                </div>
                <Link
                  aria-label="VAS company page"
                  to="/companies/vas"
                  classNames={styles.main__link}
                >
                  Learn More
                </Link>
              </div>

              <div className={styles.main__block}></div>
            </div>

            <div className={styles.main__row}>
              <div className={styles.main__block}></div>

              <div
                tabIndex={0}
                className={classNames(styles.main__block, styles.peak__block)}
              >
                <div className={styles.main__img}>
                  <GatsbyImage
                    alt="Lab worker looking in the microscope"
                    image={data.peak.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </div>
                <div className={styles.main__logo}>
                  <PeakCopySVG />
                </div>
                <Link
                  aria-label="Peak company page"
                  to="/companies/peak"
                  classNames={styles.main__link}
                >
                  Learn More
                </Link>
              </div>

              <div className={styles.main__block}></div>
              <div
                tabIndex={0}
                className={classNames(styles.main__block, styles.ova__block)}
              >
                <div className={styles.main__img}>
                  <GatsbyImage
                    alt="People in the field"
                    image={data.transOva.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </div>
                <div className={styles.main__logo}>
                  <TransOvaSVG />
                </div>
                <Link
                  aria-label="Trans Ova company page"
                  to="/companies/trans-ova-genetics"
                  classNames={styles.main__link}
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className={styles.main__row}>
              <div
                tabIndex={0}
                className={classNames(
                  styles.main__block,
                  styles.agSource__block
                )}
              >
                <div className={styles.main__img}>
                  <GatsbyImage
                    alt="Farm lab"
                    image={data.agSource.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </div>
                <div className={styles.main__logo}>
                  <AgSourceSVG />
                </div>
                <Link
                  aria-label="AgSource company page"
                  to="/companies/agsource"
                  classNames={styles.main__link}
                >
                  Learn More
                </Link>
              </div>
              <div className={styles.main__block}></div>

              <div
                tabIndex={0}
                className={classNames(
                  styles.main__block,
                  styles.jetstream__block
                )}
              >
                <div className={styles.main__img}>
                  <GatsbyImage
                    alt="Milk gathering device"
                    image={data.jetstream.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="50% 50%"
                  />
                </div>
                <div className={styles.main__logo}>
                  <JetstreamSVG />
                </div>
                <Link
                  aria-label="Jetstream company page"
                  to="/companies/jetstream-genetics"
                  classNames={styles.main__link}
                >
                  Learn More
                </Link>
              </div>

              <div className={styles.main__block}></div>
            </div>
          </div>
        </div>

        {/* --------------------MOBILE------------------------- */}

        <div className={styles.mobile}>
          <div className={styles.main}>
            <div className={styles.main__info}>
              <h2
                className={classNames(
                  "headline--decorated",
                  styles.main__headline
                )}
              >
                Urus <span>Companies</span>
              </h2>
              <div>
                <p className={styles.main__subheadline}>Urus is the source</p>
                <p className={styles.main__paragraph}>
                  for technologies to improve the profitability of progressive
                  commercial dairy and beef operations worldwide.
                </p>
              </div>
            </div>
            <div className={styles.main__row}>
              <div className={styles.main__block}></div>
              <div
                className={classNames(styles.main__block, styles.alta__block)}
              >
                <Link
                  aria-label="Alta company page"
                  to="/companies/alta-genetics"
                >
                  <div className={styles.main__logo}>
                    <AltaSVG />
                  </div>
                  <span className={styles.main__hint__mobile}>Learn More</span>
                </Link>
              </div>
            </div>
            <div className={styles.main__row}>
              <div
                className={classNames(styles.main__block, styles.genex__block)}
              >
                <Link aria-label="Genex company page" to="/companies/genex">
                  <div className={styles.main__logo}>
                    <GenexSVG />
                  </div>
                  <span className={styles.main__hint__mobile}>Learn More</span>
                </Link>
              </div>

              <div className={styles.main__block}></div>
            </div>

            <div className={styles.main__row}>
              <div className={styles.main__block}></div>
              <div
                className={classNames(styles.main__block, styles.vas__block)}
              >
                <Link aria-label="VAS company page" to="/companies/vas">
                  <div className={styles.main__logo}>
                    <VasSVG />
                  </div>
                  <span className={styles.main__hint__mobile}>Learn More</span>
                </Link>
              </div>
            </div>
            <div className={styles.main__row}>
              <div
                className={classNames(styles.main__block, styles.peak__block)}
              >
                <Link aria-label="Peak company page" to="/companies/peak">
                  <div className={styles.main__logo}>
                    <PeakSVG />
                  </div>
                  <span className={styles.main__hint__mobile}>Learn More</span>
                </Link>
              </div>

              <div className={styles.main__block}></div>
            </div>
            <div className={styles.main__row}>
              <div className={styles.main__block}></div>
              <div
                className={classNames(
                  styles.main__block,
                  styles.jetstream__block
                )}
              >
                <Link
                  aria-label="Jetstream company page"
                  to="/companies/jetstream-genetics"
                >
                  <div className={styles.main__logo}>
                    <JetstreamSVG />
                  </div>
                  <span className={styles.main__hint__mobile}>Learn More</span>
                </Link>
              </div>
            </div>
            <div className={styles.main__row}>
              <div
                className={classNames(styles.main__block, styles.sccl__block)}
              >
                <Link aria-label="SCCL company page" to="/companies/sccl">
                  <div className={styles.main__logo}>
                    <ScclSVG />
                  </div>
                  <span className={styles.main__hint__mobile}>Learn More</span>
                </Link>
              </div>
            </div>
            <div className={styles.main__row}>
              <div className={styles.main__block}></div>
              <div
                className={classNames(styles.main__block, styles.sccl__block)}
              >
                <Link
                  aria-label="Trans Ova Genetics company page"
                  to="/companies/trans-ova-genetics"
                >
                  <div className={styles.main__logo}>
                    <TransOvaSVG />
                  </div>
                  <span className={styles.main__hint__mobile}>Learn More</span>
                </Link>
              </div>
            </div>
            <div className={styles.main__row}>
              <div
                className={classNames(styles.main__block, styles.sccl__block)}
              >
                <Link
                  aria-label="AgSource company page"
                  to="/companies/agsource"
                >
                  <div className={styles.main__logo}>
                    <AgSourceSVG />
                  </div>
                  <span className={styles.main__hint__mobile}>Learn More</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompaniesSection
