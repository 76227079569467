import React, { useState } from "react"

import Slides from "./components/slides"
import Controller from "./components/controller"
import Info from "./components/info"

import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/navigation"

import useHero from "~/hooks/cms/home/useHero"

const HeroSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(1)
  const [swiper, setSwiper] = useState(null)
  const items = useHero()

  return (
    <section>
      <Slides
        setCurrentIndex={setCurrentIndex}
        setSwiper={setSwiper}
        items={items}
      />
      <Info />
      <Controller
        currentIndex={currentIndex}
        swiper={swiper}
        totalItems={items.length}
      />
    </section>
  )
}

export default HeroSection
