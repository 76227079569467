// extracted by mini-css-extract-plugin
export var agSource__block = "index-module--agSource__block--db7d2";
export var alta__block = "index-module--alta__block--e0a81";
export var backgroundImage = "index-module--background-image--00475";
export var desktop = "index-module--desktop--0aeaa";
export var genex__block = "index-module--genex__block--2e590";
export var jetstream__block = "index-module--jetstream__block--0ef72";
export var main = "index-module--main--6de1c";
export var main__block = "index-module--main__block--b88d6";
export var main__headline = "index-module--main__headline--1ab67";
export var main__hint__mobile = "index-module--main__hint__mobile--f17f5";
export var main__img = "index-module--main__img--a1f1d";
export var main__info = "index-module--main__info--4895b";
export var main__link = "index-module--main__link--910a3";
export var main__logo = "index-module--main__logo--b4c71";
export var main__paragraph = "index-module--main__paragraph--a8c4e";
export var main__row = "index-module--main__row--13d99";
export var main__subheadline = "index-module--main__subheadline--9b81c";
export var mobile = "index-module--mobile--1d1e9";
export var ova__block = "index-module--ova__block--5f8f0";
export var peak__block = "index-module--peak__block--72703";
export var root = "index-module--root--85c38";
export var sccl__block = "index-module--sccl__block--63522";
export var vas__block = "index-module--vas__block--63644";
export var wrapper = "index-module--wrapper--77f82";