// extracted by mini-css-extract-plugin
export var info = "index-module--info--f3b20";
export var info__button = "index-module--info__button--6b99c";
export var info__headline = "index-module--info__headline--aa56d";
export var info__text = "index-module--info__text--d524f";
export var main = "index-module--main--67de6";
export var main__button = "index-module--main__button--9dd80";
export var main__frame = "index-module--main__frame--28356";
export var main__frame__wrapper = "index-module--main__frame__wrapper--c4c33";
export var main__headline = "index-module--main__headline--d9db4";
export var main__img = "index-module--main__img--2df3c";
export var main__video = "index-module--main__video--c979c";
export var main__video__playing = "index-module--main__video__playing--1d4df";
export var root = "index-module--root--d1abc";
export var wrapper = "index-module--wrapper--a6f30";