import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "~/components/ui/container"
import * as styles from "./index.module.scss"
import classNames from "classnames"
import { useState } from "react"

const FutureSection: React.FC = () => {
  const [isTopItemMoved, setIsTopItemMoved] = useState(false)
  const [isBottomItemMoved, setIsBottomItemMoved] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      bg1: file(relativePath: { eq: "home/future/bg1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      bg2: file(relativePath: { eq: "home/future/bg2.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      bg3: file(relativePath: { eq: "home/future/bg3.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      bg4: file(relativePath: { eq: "home/future/bg4.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      bg5: file(relativePath: { eq: "home/future/bg5.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      bg6: file(relativePath: { eq: "home/future/bg6.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      bg7: file(relativePath: { eq: "home/future/bg7.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      bg8: file(relativePath: { eq: "home/future/bg8.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      vas: file(relativePath: { eq: "home/future/vas.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      urus: file(relativePath: { eq: "home/future/urus.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      dairylearning: file(
        relativePath: { eq: "home/future/dairylearning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      sccl: file(relativePath: { eq: "home/future/sccl.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      genex: file(relativePath: { eq: "home/future/genex.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      alta: file(relativePath: { eq: "home/future/alta.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      peak: file(relativePath: { eq: "home/future/peak.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      agsource: file(relativePath: { eq: "home/future/agsource.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  return (
    <section>
      <div>
        <Container>
          <h2 className={classNames("headline--decorated", styles.headline)}>
            The Future of <span>Urus</span>
          </h2>
          <p className={styles.paragraph}>
            URUS is committed to building a sustainable future for the next
            generation
          </p>
        </Container>
        <div className={styles.row}>
          <div
            tabIndex={0}
            className={classNames(styles.item, styles.one_one)}
            onMouseOver={() => setIsTopItemMoved(true)}
            onMouseLeave={() => setIsTopItemMoved(false)}
            onFocus={() => setIsTopItemMoved(true)}
            onBlur={() => setIsTopItemMoved(false)}
          >
            <div className={styles.item__info}>
              <div className={styles.item__label}>Data Based Management</div>
              <div className={styles.item__company}>
                <p className={styles.item__paragraph}>
                  Connecting Farm Management to data
                </p>
                <div className={styles.item__logo__wrapper}>
                  <div className={styles.item__logo}>
                    <GatsbyImage
                      alt="VAS logo"
                      image={data.vas.childImageSharp.gatsbyImageData}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item__img}>
              <GatsbyImage
                alt="Man with cows"
                image={data.bg1.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>

          <div
            tabIndex={0}
            className={classNames(styles.item, styles.one_two, {
              [styles.item__top__moved]: isTopItemMoved,
            })}
          >
            <div className={styles.item__info}>
              <div className={styles.item__label}>Prosperous People</div>
              <div className={styles.item__company}>
                <p className={styles.item__paragraph}>
                  Global Development and Sustainability
                </p>
                <div className={styles.item__logo__wrapper}>
                  <div className={styles.item__logo}>
                    <GatsbyImage
                      alt="Urus logo"
                      image={data.urus.childImageSharp.gatsbyImageData}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item__img}>
              <GatsbyImage
                alt="Family"
                image={data.bg2.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>
          <div className={styles.item}></div>
          <div
            tabIndex={0}
            className={classNames(styles.item, styles.one_four)}
          >
            <div className={styles.item__info}>
              <div className={styles.item__label}>Focused Education</div>
              <div className={styles.item__company}>
                <p className={styles.item__paragraph}>
                  Educating Farmers How to Feed the World
                </p>
                <div className={styles.item__logo__wrapper}>
                  <div className={styles.item__logo}>
                    <GatsbyImage
                      alt="Urus logo"
                      image={data.urus.childImageSharp.gatsbyImageData}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <GatsbyImage
                className={styles.item__img}
                alt="Man and woman working"
                image={data.bg3.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div tabIndex={0} className={classNames(styles.item, styles.two_one)}>
            <div className={styles.item__info}>
              <div className={styles.item__label}>Safe Food</div>
              <div className={styles.item__company}>
                <p className={styles.item__paragraph}>
                  Milk Testing & Quality Control
                </p>
                <div className={styles.item__logo__wrapper}>
                  <div className={styles.item__logo}>
                    <GatsbyImage
                      alt="AgSource logo"
                      image={data.agsource.childImageSharp.gatsbyImageData}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item__img}>
              <GatsbyImage
                alt="Girl drinking milk in the field"
                image={data.bg4.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>

          <div className={styles.item}></div>
          <div
            tabIndex={0}
            className={classNames(styles.item, styles.two_three)}
          >
            <div className={styles.item__info}>
              <div className={styles.item__label}>Durable Calves</div>
              <div className={styles.item__company}>
                <p className={styles.item__paragraph}>
                  Reducing Calf Mortality Rates with Colostrum
                </p>
                <div className={styles.item__logo__wrapper}>
                  <div className={styles.item__logo}>
                    <GatsbyImage
                      alt="SCCL logo"
                      image={data.sccl.childImageSharp.gatsbyImageData}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.item__img}>
              <GatsbyImage
                alt="Calf"
                image={data.bg5.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>
          <div className={styles.item}></div>
        </div>
        <div className={styles.row}>
          <div
            tabIndex={0}
            className={classNames(styles.item, styles.three_one)}
          >
            <div className={styles.item__info}>
              <div className={styles.item__label}>Productive Cows</div>
              <div className={styles.item__company}>
                <p className={styles.item__paragraph}>
                  Breeding Programs & Genetics
                </p>
                <div className={styles.item__logo__wrapper}>
                  <div className={styles.item__logo}>
                    <GatsbyImage
                      alt="Alta logo"
                      image={data.alta.childImageSharp.gatsbyImageData}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>

                  <div className={styles.item__logo}>
                    <GatsbyImage
                      alt="Genex Logo"
                      image={data.genex.childImageSharp.gatsbyImageData}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item__img}>
              <GatsbyImage
                alt="Cows in production centre"
                image={data.bg6.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>

          <div className={styles.item}></div>
          <div
            tabIndex={0}
            className={classNames(styles.item, styles.three_three, {
              [styles.item__bottom__moved]: isBottomItemMoved,
            })}
          >
            <div className={styles.item__info}>
              <div className={styles.item__label}>Elite Genetics</div>
              <div className={styles.item__company}>
                <p className={styles.item__paragraph}>
                  The Source of Superior Genetics to the Urus Brands and to the
                  World
                </p>
                <div className={styles.item__logo__wrapper}>
                  <div className={styles.item__logo}>
                    <GatsbyImage
                      alt="Peak logo"
                      image={data.peak.childImageSharp.gatsbyImageData}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item__img}>
              <GatsbyImage
                alt="Genetic Chemicals"
                image={data.bg7.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>
          <div
            tabIndex={0}
            className={classNames(styles.item, styles.three_four)}
            onMouseOver={() => setIsBottomItemMoved(true)}
            onMouseLeave={() => setIsBottomItemMoved(false)}
            onFocus={() => setIsBottomItemMoved(true)}
            onBlur={() => setIsBottomItemMoved(false)}
          >
            <div className={styles.item__info}>
              <div className={styles.item__label}>Healthy Soil</div>
              <div className={styles.item__company}>
                <p className={styles.item__paragraph}>
                  Soil Testing and Management
                </p>
                <div className={styles.item__logo__wrapper}>
                  <div className={styles.item__logo}>
                    <GatsbyImage
                      alt="AgSource logo"
                      image={data.agsource.childImageSharp.gatsbyImageData}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.item__img}>
              <GatsbyImage
                alt="Soil"
                image={data.bg8.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FutureSection
