// extracted by mini-css-extract-plugin
export var headline = "index-module--headline--482ae";
export var item = "index-module--item--5e26e";
export var item__bottom__moved = "index-module--item__bottom__moved--64871";
export var item__company = "index-module--item__company--13cb1";
export var item__img = "index-module--item__img--13029";
export var item__info = "index-module--item__info--b8308";
export var item__label = "index-module--item__label--1e631";
export var item__logo = "index-module--item__logo--221a3";
export var item__logo__wrapper = "index-module--item__logo__wrapper--117b1";
export var item__paragraph = "index-module--item__paragraph--0f8d2";
export var item__top__moved = "index-module--item__top__moved--06d3f";
export var one_four = "index-module--one_four--b1673";
export var one_one = "index-module--one_one--0c559";
export var one_two = "index-module--one_two--c73f6";
export var paragraph = "index-module--paragraph--c9fcf";
export var row = "index-module--row--08801";
export var three_four = "index-module--three_four--2d453";
export var three_one = "index-module--three_one--7cf9b";
export var three_three = "index-module--three_three--85444";
export var two_one = "index-module--two_one--f4fc0";
export var two_three = "index-module--two_three--97f43";