import { graphql, useStaticQuery } from "gatsby"

const useHero = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicHomepage {
        data {
          body {
            ... on PrismicHomepageDataBodyHeroSection {
              slice_type
              id
              items {
                image {
                  alt
                  localFile {
                    id
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.prismicHomepage.data.body.find(
    item => item.slice_type === "hero_section"
  ).items
}

export default useHero
