import React from "react"

import ChevronLeft from "~/images/home/chevron_left.svg"
import ChevronRight from "~/images/home/chevron_right.svg"

import * as styles from "../index.module.scss"

interface Props {
  currentIndex: number
  swiper: any
  totalItems: number
}

const Controller: React.FC<Props> = ({ currentIndex, swiper, totalItems }) => {
  return (
    <div className={styles.controller}>
      <p>It all centers around the cow</p>
      <p>
        {currentIndex} / {totalItems}
      </p>
      <div className={styles.controller__buttons}>
        <button
          aria-label="Previous Image"
          className={styles.controller__button}
          onClick={() => {
            swiper.slidePrev()
          }}
        >
          <ChevronLeft />
        </button>
        <button
          aria-label="Next Image"
          className={styles.controller__button}
          onClick={() => {
            swiper.slideNext()
          }}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  )
}

export default Controller
