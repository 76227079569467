import { graphql, useStaticQuery } from "gatsby"

const useAbout = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicHomepage {
        data {
          body {
            ... on PrismicHomepageDataBodyAboutSection {
              slice_type
              id
              primary {
                text {
                  text
                  raw
                }
                frame_title {
                  text
                  raw
                }
                video {
                  localFile {
                    extension
                    publicURL
                  }
                }
                frame_background {
                  alt
                  localFile {
                    id
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return data.prismicHomepage.data.body.find(
    item => item.slice_type === "about_section"
  )
}

export default useAbout
